import React, { forwardRef, useImperativeHandle, useState } from "react";
import { RightArrow } from "../../assets/svg";
import { ConditionalRendering, _readableTextCap, base64toFile } from "../../utils/methods";
import { AddOTPMobileUser, CButton, ConfirmationUqudo } from "..";
import { Popconfirm } from "antd";
import { UpdateEidMobileUser, clearState, createMobileAppUser, getOCRToken, getOTPEidBypass, lightUserToPortalUser, uploadUqudoImages } from "../../store/actions/Employees.action";
import { useDispatch, useSelector } from "react-redux";
import { CiMobile1 } from "react-icons/ci";
import createUqudoSdk from './UqudoSdkWrapper';
import OTPBypassEid from "./OTPBypassEid";
import { MOBILE_USER_STATUS } from "../../utils/constants";

export const DOC_TYPES = {
    ACCOUNT_VERIFICATION: "ACCOUNT_VERIFICATION",
    RENEWED: "RENEWED",
};

const CUqudo = forwardRef(({ empId, employeeId, getEmployee, toggleEditDrawer, employee, mobileUser, containerClass }, ref) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees, auth }) => {
        return {
            user: auth?.user,
            pageLoading: employees.createMobileAppUserLoading || employees.verifyMobileAppUserLoading || employees.getOCRTokenLoading || employees.uploadUqudoImagesLoading || employees.getOTPBypassEIDLoading,
            createMobileAppUserLoading: employees.createMobileAppUserLoading,
            responseMobileUserData: employees.holdingMobileUserData,
            responseOCR: employees.holdingOCRTokenResponse,
            getOCRTokenLoading: employees.getOCRTokenLoading,
            getOTPBypassEIDLoading: employees.getOTPBypassEIDLoading,
        };
    });

    const docType = mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && mobileUser?.isEidScanned ? DOC_TYPES.RENEWED : DOC_TYPES.ACCOUNT_VERIFICATION;

    const [uqudoToken, setUqudoToken] = useState('');
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const [isOTPModalMobileUserOpen, setIsOTPModalMobileUserOpen] = useState(false);
    const [isOpenOTP, setIsOpenOTP] = useState(false);
    const [otp, setOtp] = useState("");

    useImperativeHandle(ref, () => ({
        runUqudo,
        submitUqudo,
        getOCRTokenFunc
    }));

    const checkUserCondition = (response) => {
        const status = response?.data?.status || response?.status;
        if (status === MOBILE_USER_STATUS?.OTP_NOT_VERIFIED) {
            setIsOTPModalMobileUserOpen(true);
        } else if (status === MOBILE_USER_STATUS?.INACTIVE) {
            getOCRTokenFunc(response);
        }
    };

    const createMobileAppUserFunc = () => {
        const payload = {
            client: empId,
            employeeId: employeeId,
        };
        dispatch(createMobileAppUser(payload, checkUserCondition));
    };

    const callbackOCR = (obj = {}) => {
        runUqudo(obj);
        setIsOTPModalMobileUserOpen(false);
    };

    const getOCRTokenFunc = (response) => {
        const payload = {
            client: empId,
            employeeId: employeeId,
            docType,
            ...(docType === DOC_TYPES.ACCOUNT_VERIFICATION && { id: response?._id || reduxState?.responseMobileUserData?.data?._id || reduxState?.responseMobileUserData?._id }),
        };
        dispatch(getOCRToken(payload, callbackOCR));
    }

    const callBackSubmitUqudo = () => {
        getEmployee();
        setIsOpenConfirmation(false);
        dispatch(clearState());
    };

    const submitUqudo = (selfie, resUqudoToken) => {
        if (docType === DOC_TYPES.RENEWED) {
            const selfieFile = base64toFile(selfie, employeeId, 'image/jpeg')
            const body = new FormData();
            body.append('client', empId);
            body.append('employee', employeeId);
            body.append('docType', docType);
            body.append('token', uqudoToken || resUqudoToken);
            body.append('selfie', selfieFile);
            dispatch(UpdateEidMobileUser(body, callBackSubmitUqudo));
        } else if (docType === DOC_TYPES.ACCOUNT_VERIFICATION) {
            const selfieFile = base64toFile(selfie, employeeId, 'image/jpeg')
            const body = new FormData();
            body.append('client', empId);
            body.append('employeeId', employeeId);
            body.append('token', uqudoToken || resUqudoToken);
            body.append('id', reduxState?.responseMobileUserData?.data?._id || reduxState?.responseMobileUserData?._id || mobileUser?._id);
            body.append('selfie', selfieFile);
            if (mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE) {
                dispatch(lightUserToPortalUser(body, callBackSubmitUqudo));
            } else {
                dispatch(uploadUqudoImages(body, callBackSubmitUqudo));
            }
        }
    };

    const runUqudo = async (obj = {}) => {
        try {
            const options = {
                baseURL: 'https://id.uqudo.io',
                accessToken: reduxState?.responseOCR?.token || obj?.token,
                userId: reduxState?.responseOCR?.uqudoUserId || obj?.uqudoUserId
            }
            const { sdk, docType } = await createUqudoSdk(options);
            const result = await sdk.enrollment({
                scan: {
                    documentType: docType.UAE_ID,
                    forceUpload: true,
                },
                onSuccess: (result) => {
                    setUqudoToken(result)
                    setIsOpenConfirmation(true)
                },
                onError: (error) => {
                    console.log(error)
                },
            });
        } catch (error) {
            console.log(error)
            getEmployee()
        }
    };

    const callbackBypassEid = (response) => {
        setIsOpenOTP(true);
        setOtp(response);
    }

    const getOCROnBypassEID = () => {
        const payload = {
            client: empId,
            employeeId: employeeId,
        }
        dispatch(getOTPEidBypass(payload, callbackBypassEid));
    }

    const renderButtonOnCondition = () => {
        if (mobileUser && Object.keys(mobileUser)?.length) {

            let userIsInActive = mobileUser?.status === MOBILE_USER_STATUS?.INACTIVE;
            let userIsActive = mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE;
            let userOtpNotVerified = mobileUser?.status === MOBILE_USER_STATUS?.OTP_NOT_VERIFIED;

            // if (employee?.isApplicationUser && docType === DOC_TYPES.RENEWED) {
            //     return {
            //         title: "Edit EID Mobile User",
            //         onClick: getOCRTokenFunc
            //     }
            // } else
            if (userOtpNotVerified || userIsInActive) {
                return {
                    title: userOtpNotVerified ? "Verify Mobile App User" : userIsInActive ? "Upload EID for Mobile App User" : "",
                    onClick: createMobileAppUserFunc
                }
            } else if (userIsActive && mobileUser?.isEidScanned && mobileUser?.isPortalUser?.status) {
                return {
                    title: "Bypass Uqudo",
                    onClick: getOCROnBypassEID
                }
            } else if (userIsActive && !mobileUser?.isEidScanned && mobileUser?.isLightUser) {
                return {
                    title: "Upload EID",
                    onClick: () => getOCRTokenFunc(mobileUser)
                }
            } else if (userIsActive && mobileUser?.isEidScanned && !mobileUser?.isLightUser && !mobileUser?.isPortalUser?.status) {
                return {
                    title: "",
                    onClick: () => null,
                }
            }
        }
        return {
            title: "",
            onClick: () => null,
        }
    };

    const title = renderButtonOnCondition()?.title;
    const onClick = renderButtonOnCondition()?.onClick;

    return <>
        <ConditionalRendering
            condition={mobileUser && Object.keys(mobileUser)?.length && title?.length}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title={title}
                leftIcon={"edit"}
                disabled={reduxState?.getOCRTokenLoading}
                loading={reduxState?.getOCRTokenLoading || reduxState?.getOTPBypassEIDLoading || reduxState?.createMobileAppUserLoading}
                onClick={onClick}
            />}
        />
        <ConditionalRendering
            condition={!(mobileUser && Object.keys(mobileUser)?.length) && !title?.length}
            children={
                <Popconfirm
                    title={<p>Verification code will be sent to this number: <b>+{employee?.mobileNo}</b>. Keep your phone handy!</p>}
                    onConfirm={createMobileAppUserFunc}
                    onCancel={() => toggleEditDrawer(true)}
                    okText="Continue"
                    cancelText="Update Phone Number"
                >
                    <CButton
                        containerClass={`flex0 ${containerClass}`}
                        buttonType="gradient"
                        title="Create Mobile App User"
                        leftIcon={(className) => <CiMobile1 className={className} />}
                        disabled={reduxState?.createMobileAppUserLoading}
                        loading={reduxState?.createMobileAppUserLoading}
                        rightIcon={(className) => <RightArrow className={className} />}
                    />
                </Popconfirm>
            }
        />
        <AddOTPMobileUser
            loading={reduxState?.createMobileAppUserLoading}
            isOpen={isOTPModalMobileUserOpen}
            setIsOpen={setIsOTPModalMobileUserOpen}
            employeeId={employee?._id}
            clientId={empId}
            getOCRTokenFunc={getOCRTokenFunc}
        />
        <ConfirmationUqudo isOpen={isOpenConfirmation} setIsOpen={setIsOpenConfirmation} data={uqudoToken} submitUqudo={submitUqudo} runUqudo={runUqudo} />
        <OTPBypassEid isOpen={isOpenOTP} setIsOpen={setIsOpenOTP} otp={otp} />
    </>

});

export default CUqudo;
